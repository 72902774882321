/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingHistoryListDtodlmBaseResponse } from '../models/booking-history-list-dtodlm-base-response';
import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { Form10AInformation } from '../models/form-10-a-information';
import { Form10AInformationDlmBaseResponse } from '../models/form-10-a-information-dlm-base-response';
import { Form10AInformationListDlmBaseResponse } from '../models/form-10-a-information-list-dlm-base-response';
import { FormExaminationFaultDto } from '../models/form-examination-fault-dto';
import { FormRectificationWorkDto } from '../models/form-rectification-work-dto';
import { Int64NullableDlmBaseResponse } from '../models/int-64-nullable-dlm-base-response';
import { NewFormDtoDlmBaseResponse } from '../models/new-form-dto-dlm-base-response';
import { QuoteInformationDtodlmBaseResponse } from '../models/quote-information-dtodlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class Form10AService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiForm10AForm10AGet
   */
  static readonly ApiForm10AForm10AGetPath = '/api/Form10a/form10a';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10AGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10AGet$Plain$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10AGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10AGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10AGet$Plain(params?: {
    form10aId?: number;
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AForm10AGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10AGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10AGet$Json$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10AGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10AGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10AGet$Json(params?: {
    form10aId?: number;
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AForm10AGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AForm10ALoadcellGet
   */
  static readonly ApiForm10AForm10ALoadcellGetPath = '/api/Form10a/form10a-loadcell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10ALoadcellGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellGet$Plain$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10ALoadcellGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10ALoadcellGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellGet$Plain(params?: {
    loadcellId?: number;
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AForm10ALoadcellGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10ALoadcellGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellGet$Json$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10ALoadcellGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10ALoadcellGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellGet$Json(params?: {
    loadcellId?: number;
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AForm10ALoadcellGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AForm10ALoadcellsInGroupGet
   */
  static readonly ApiForm10AForm10ALoadcellsInGroupGetPath = '/api/Form10a/form10a-loadcells-in-group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10ALoadcellsInGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellsInGroupGet$Plain$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10ALoadcellsInGroupGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10ALoadcellsInGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellsInGroupGet$Plain(params?: {
    loadcellId?: number;
  }): Observable<Form10AInformationListDlmBaseResponse> {

    return this.apiForm10AForm10ALoadcellsInGroupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationListDlmBaseResponse>) => r.body as Form10AInformationListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AForm10ALoadcellsInGroupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellsInGroupGet$Json$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<Form10AInformationListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AForm10ALoadcellsInGroupGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AForm10ALoadcellsInGroupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AForm10ALoadcellsInGroupGet$Json(params?: {
    loadcellId?: number;
  }): Observable<Form10AInformationListDlmBaseResponse> {

    return this.apiForm10AForm10ALoadcellsInGroupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationListDlmBaseResponse>) => r.body as Form10AInformationListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ANewJobPost
   */
  static readonly ApiForm10ANewJobPostPath = '/api/Form10a/new-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ANewJobPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ANewJobPost$Plain$Response(params?: {
    overrideEmployeeId?: number;
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<NewFormDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ANewJobPostPath, 'post');
    if (params) {
      rb.query('overrideEmployeeId', params.overrideEmployeeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewFormDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ANewJobPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ANewJobPost$Plain(params?: {
    overrideEmployeeId?: number;
    body?: Form10AInformation
  }): Observable<NewFormDtoDlmBaseResponse> {

    return this.apiForm10ANewJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NewFormDtoDlmBaseResponse>) => r.body as NewFormDtoDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ANewJobPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ANewJobPost$Json$Response(params?: {
    overrideEmployeeId?: number;
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<NewFormDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ANewJobPostPath, 'post');
    if (params) {
      rb.query('overrideEmployeeId', params.overrideEmployeeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewFormDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ANewJobPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ANewJobPost$Json(params?: {
    overrideEmployeeId?: number;
    body?: Form10AInformation
  }): Observable<NewFormDtoDlmBaseResponse> {

    return this.apiForm10ANewJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NewFormDtoDlmBaseResponse>) => r.body as NewFormDtoDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ALinkNewJobPost
   */
  static readonly ApiForm10ALinkNewJobPostPath = '/api/Form10a/link-new-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ALinkNewJobPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ALinkNewJobPost$Plain$Response(params?: {
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<NewFormDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ALinkNewJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewFormDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ALinkNewJobPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ALinkNewJobPost$Plain(params?: {
    body?: Form10AInformation
  }): Observable<NewFormDtoDlmBaseResponse> {

    return this.apiForm10ALinkNewJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NewFormDtoDlmBaseResponse>) => r.body as NewFormDtoDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ALinkNewJobPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ALinkNewJobPost$Json$Response(params?: {
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<NewFormDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ALinkNewJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NewFormDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ALinkNewJobPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10ALinkNewJobPost$Json(params?: {
    body?: Form10AInformation
  }): Observable<NewFormDtoDlmBaseResponse> {

    return this.apiForm10ALinkNewJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NewFormDtoDlmBaseResponse>) => r.body as NewFormDtoDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AEditForm10APost
   */
  static readonly ApiForm10AEditForm10APostPath = '/api/Form10a/edit-form10a';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AEditForm10APost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AEditForm10APost$Plain$Response(params?: {
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AEditForm10APostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AEditForm10APost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AEditForm10APost$Plain(params?: {
    body?: Form10AInformation
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AEditForm10APost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AEditForm10APost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AEditForm10APost$Json$Response(params?: {
    body?: Form10AInformation
  }): Observable<StrictHttpResponse<Form10AInformationDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AEditForm10APostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Form10AInformationDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AEditForm10APost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AEditForm10APost$Json(params?: {
    body?: Form10AInformation
  }): Observable<Form10AInformationDlmBaseResponse> {

    return this.apiForm10AEditForm10APost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Form10AInformationDlmBaseResponse>) => r.body as Form10AInformationDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ADeleteFormPartPost
   */
  static readonly ApiForm10ADeleteFormPartPostPath = '/api/Form10a/delete-form-part';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormPartPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormPartPost$Plain$Response(params?: {
    formPartId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormPartPostPath, 'post');
    if (params) {
      rb.query('formPartId', params.formPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormPartPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormPartPost$Plain(params?: {
    formPartId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormPartPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormPartPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormPartPost$Json$Response(params?: {
    formPartId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormPartPostPath, 'post');
    if (params) {
      rb.query('formPartId', params.formPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormPartPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormPartPost$Json(params?: {
    formPartId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormPartPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ADeleteFormExaminationFaultPost
   */
  static readonly ApiForm10ADeleteFormExaminationFaultPostPath = '/api/Form10a/delete-form-examination-fault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormExaminationFaultPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormExaminationFaultPost$Plain$Response(params?: {
    formExaminationFaultId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormExaminationFaultPostPath, 'post');
    if (params) {
      rb.query('formExaminationFaultId', params.formExaminationFaultId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormExaminationFaultPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormExaminationFaultPost$Plain(params?: {
    formExaminationFaultId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormExaminationFaultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormExaminationFaultPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormExaminationFaultPost$Json$Response(params?: {
    formExaminationFaultId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormExaminationFaultPostPath, 'post');
    if (params) {
      rb.query('formExaminationFaultId', params.formExaminationFaultId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormExaminationFaultPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormExaminationFaultPost$Json(params?: {
    formExaminationFaultId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormExaminationFaultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ADeleteFormRectificationWorkPost
   */
  static readonly ApiForm10ADeleteFormRectificationWorkPostPath = '/api/Form10a/delete-form-rectification-work';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormRectificationWorkPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormRectificationWorkPost$Plain$Response(params?: {
    formRectificationWorkId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormRectificationWorkPostPath, 'post');
    if (params) {
      rb.query('formRectificationWorkId', params.formRectificationWorkId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormRectificationWorkPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormRectificationWorkPost$Plain(params?: {
    formRectificationWorkId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormRectificationWorkPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ADeleteFormRectificationWorkPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormRectificationWorkPost$Json$Response(params?: {
    formRectificationWorkId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ADeleteFormRectificationWorkPostPath, 'post');
    if (params) {
      rb.query('formRectificationWorkId', params.formRectificationWorkId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ADeleteFormRectificationWorkPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ADeleteFormRectificationWorkPost$Json(params?: {
    formRectificationWorkId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ADeleteFormRectificationWorkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ASetNoRepairPost
   */
  static readonly ApiForm10ASetNoRepairPostPath = '/api/Form10a/set-no-repair';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetNoRepairPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetNoRepairPost$Plain$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetNoRepairPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetNoRepairPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetNoRepairPost$Plain(params?: {
    form10aId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetNoRepairPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetNoRepairPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetNoRepairPost$Json$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetNoRepairPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetNoRepairPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetNoRepairPost$Json(params?: {
    form10aId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetNoRepairPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ASetAwaitingClientApprovalPost
   */
  static readonly ApiForm10ASetAwaitingClientApprovalPostPath = '/api/Form10a/set-awaiting-client-approval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetAwaitingClientApprovalPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetAwaitingClientApprovalPost$Plain$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetAwaitingClientApprovalPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetAwaitingClientApprovalPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetAwaitingClientApprovalPost$Plain(params?: {
    form10aId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetAwaitingClientApprovalPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetAwaitingClientApprovalPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetAwaitingClientApprovalPost$Json$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetAwaitingClientApprovalPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetAwaitingClientApprovalPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetAwaitingClientApprovalPost$Json(params?: {
    form10aId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetAwaitingClientApprovalPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ASetStatusByFormIdPost
   */
  static readonly ApiForm10ASetStatusByFormIdPostPath = '/api/Form10a/set-status-by-form-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetStatusByFormIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetStatusByFormIdPost$Plain$Response(params?: {
    form10aId?: number;
    status?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetStatusByFormIdPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetStatusByFormIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetStatusByFormIdPost$Plain(params?: {
    form10aId?: number;
    status?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetStatusByFormIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ASetStatusByFormIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetStatusByFormIdPost$Json$Response(params?: {
    form10aId?: number;
    status?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ASetStatusByFormIdPostPath, 'post');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ASetStatusByFormIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ASetStatusByFormIdPost$Json(params?: {
    form10aId?: number;
    status?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10ASetStatusByFormIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AQuotePost
   */
  static readonly ApiForm10AQuotePostPath = '/api/Form10a/quote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AQuotePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AQuotePost$Plain$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Int64NullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AQuotePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64NullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AQuotePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AQuotePost$Plain(params?: {
    body?: Array<number>
  }): Observable<Int64NullableDlmBaseResponse> {

    return this.apiForm10AQuotePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64NullableDlmBaseResponse>) => r.body as Int64NullableDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AQuotePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AQuotePost$Json$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Int64NullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AQuotePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64NullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AQuotePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AQuotePost$Json(params?: {
    body?: Array<number>
  }): Observable<Int64NullableDlmBaseResponse> {

    return this.apiForm10AQuotePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64NullableDlmBaseResponse>) => r.body as Int64NullableDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AQuoteInformationPost
   */
  static readonly ApiForm10AQuoteInformationPostPath = '/api/Form10a/quote-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AQuoteInformationPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AQuoteInformationPost$Plain$Response(params?: {
    quoteId?: number;
  }): Observable<StrictHttpResponse<QuoteInformationDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AQuoteInformationPostPath, 'post');
    if (params) {
      rb.query('quoteId', params.quoteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuoteInformationDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AQuoteInformationPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AQuoteInformationPost$Plain(params?: {
    quoteId?: number;
  }): Observable<QuoteInformationDtodlmBaseResponse> {

    return this.apiForm10AQuoteInformationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuoteInformationDtodlmBaseResponse>) => r.body as QuoteInformationDtodlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AQuoteInformationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AQuoteInformationPost$Json$Response(params?: {
    quoteId?: number;
  }): Observable<StrictHttpResponse<QuoteInformationDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AQuoteInformationPostPath, 'post');
    if (params) {
      rb.query('quoteId', params.quoteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuoteInformationDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AQuoteInformationPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10AQuoteInformationPost$Json(params?: {
    quoteId?: number;
  }): Observable<QuoteInformationDtodlmBaseResponse> {

    return this.apiForm10AQuoteInformationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuoteInformationDtodlmBaseResponse>) => r.body as QuoteInformationDtodlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AUpdateExaminationFaultPost
   */
  static readonly ApiForm10AUpdateExaminationFaultPostPath = '/api/Form10a/update-examination-fault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AUpdateExaminationFaultPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateExaminationFaultPost$Plain$Response(params?: {
    body?: FormExaminationFaultDto
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AUpdateExaminationFaultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AUpdateExaminationFaultPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateExaminationFaultPost$Plain(params?: {
    body?: FormExaminationFaultDto
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10AUpdateExaminationFaultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AUpdateExaminationFaultPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateExaminationFaultPost$Json$Response(params?: {
    body?: FormExaminationFaultDto
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AUpdateExaminationFaultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AUpdateExaminationFaultPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateExaminationFaultPost$Json(params?: {
    body?: FormExaminationFaultDto
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10AUpdateExaminationFaultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10AUpdateRectificationWorkPost
   */
  static readonly ApiForm10AUpdateRectificationWorkPostPath = '/api/Form10a/update-rectification-work';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AUpdateRectificationWorkPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateRectificationWorkPost$Plain$Response(params?: {
    body?: FormRectificationWorkDto
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AUpdateRectificationWorkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AUpdateRectificationWorkPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateRectificationWorkPost$Plain(params?: {
    body?: FormRectificationWorkDto
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10AUpdateRectificationWorkPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10AUpdateRectificationWorkPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateRectificationWorkPost$Json$Response(params?: {
    body?: FormRectificationWorkDto
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10AUpdateRectificationWorkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10AUpdateRectificationWorkPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiForm10AUpdateRectificationWorkPost$Json(params?: {
    body?: FormRectificationWorkDto
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiForm10AUpdateRectificationWorkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiForm10ABookingHistoryPost
   */
  static readonly ApiForm10ABookingHistoryPostPath = '/api/Form10a/booking-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ABookingHistoryPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ABookingHistoryPost$Plain$Response(params?: {
    serialNumberId?: number;
    form10aId?: number;
  }): Observable<StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ABookingHistoryPostPath, 'post');
    if (params) {
      rb.query('serialNumberId', params.serialNumberId, {});
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ABookingHistoryPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ABookingHistoryPost$Plain(params?: {
    serialNumberId?: number;
    form10aId?: number;
  }): Observable<BookingHistoryListDtodlmBaseResponse> {

    return this.apiForm10ABookingHistoryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>) => r.body as BookingHistoryListDtodlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiForm10ABookingHistoryPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ABookingHistoryPost$Json$Response(params?: {
    serialNumberId?: number;
    form10aId?: number;
  }): Observable<StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, Form10AService.ApiForm10ABookingHistoryPostPath, 'post');
    if (params) {
      rb.query('serialNumberId', params.serialNumberId, {});
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiForm10ABookingHistoryPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiForm10ABookingHistoryPost$Json(params?: {
    serialNumberId?: number;
    form10aId?: number;
  }): Observable<BookingHistoryListDtodlmBaseResponse> {

    return this.apiForm10ABookingHistoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BookingHistoryListDtodlmBaseResponse>) => r.body as BookingHistoryListDtodlmBaseResponse)
    );
  }

}
