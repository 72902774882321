import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { QRCodeModule } from "angularx-qrcode";
import { DLMSharedModule } from "app/views/calibrations/shared/shared.module";
import { DragScrollModule } from "ngx-drag-scroll";
import { NgxPrintModule } from "ngx-print";

@Component({
  selector: "app-asset-qr-scan-dialog",
  templateUrl: "./asset-qr-scan-dialog.component.html",
  styleUrls: ["./asset-qr-scan-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    DLMSharedModule,
    MatCardModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatDividerModule,
    MatSlideToggleModule,
    DragScrollModule,
    QRCodeModule,
    ZXingScannerModule,
    NgxPrintModule,
    MatAutocompleteModule,
  ]
})
export class AssetQrScanDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AssetQrScanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data != null) {
    }
  }

  scanSuccessHandler(url: string) {
    //this will be the url to the asset info page, we can either link to that or extract the id.. format /hire/assets/asset-details/
    this.close(Number.parseInt(url.split('/').pop()));
  }

  close(result: number) {
    this.dialogRef.close(result);
  }
}
