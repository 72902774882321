/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { CalibrationCertFileDto } from '../models/calibration-cert-file-dto';
import { CalibrationCertFileDtoDlmBaseResponse } from '../models/calibration-cert-file-dto-dlm-base-response';
import { CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse } from '../models/calibration-cert-file-dto-dlm-paged-response-dlm-base-response';
import { CalibrationCertFileDtoListDlmBaseResponse } from '../models/calibration-cert-file-dto-list-dlm-base-response';
import { CalibrationResponse } from '../models/calibration-response';
import { CalibrationResponseDlmBaseResponse } from '../models/calibration-response-dlm-base-response';
import { OrderDirection } from '../models/order-direction';

@Injectable({
  providedIn: 'root',
})
export class CalibrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCalibrationCalibrationGet
   */
  static readonly ApiCalibrationCalibrationGetPath = '/api/Calibration/calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Plain$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Plain(params?: {
    calibrationId?: number;
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Json$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Json(params?: {
    calibrationId?: number;
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationCalibrationPost
   */
  static readonly ApiCalibrationCalibrationPostPath = '/api/Calibration/calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Plain$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Plain(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Json$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Json(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationEditCalibrationPost
   */
  static readonly ApiCalibrationEditCalibrationPostPath = '/api/Calibration/edit-calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationEditCalibrationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Plain$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationEditCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationEditCalibrationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Plain(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationEditCalibrationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationEditCalibrationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Json$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationEditCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationEditCalibrationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Json(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationEditCalibrationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationDeleteEquipmentPost
   */
  static readonly ApiCalibrationDeleteEquipmentPostPath = '/api/Calibration/delete-equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteEquipmentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Plain$Response(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteEquipmentPostPath, 'post');
    if (params) {
      rb.query('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteEquipmentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Plain(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteEquipmentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteEquipmentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Json$Response(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteEquipmentPostPath, 'post');
    if (params) {
      rb.query('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteEquipmentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Json(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteEquipmentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationDeleteResultPost
   */
  static readonly ApiCalibrationDeleteResultPostPath = '/api/Calibration/delete-result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteResultPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Plain$Response(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteResultPostPath, 'post');
    if (params) {
      rb.query('resultId', params.resultId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteResultPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Plain(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteResultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteResultPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Json$Response(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteResultPostPath, 'post');
    if (params) {
      rb.query('resultId', params.resultId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteResultPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Json(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteResultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationIsCertFileStaleGet
   */
  static readonly ApiCalibrationIsCertFileStaleGetPath = '/api/Calibration/is-cert-file-stale';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationIsCertFileStaleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationIsCertFileStaleGet$Plain$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationIsCertFileStaleGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationIsCertFileStaleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationIsCertFileStaleGet$Plain(params?: {
    calibrationId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationIsCertFileStaleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationIsCertFileStaleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationIsCertFileStaleGet$Json$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationIsCertFileStaleGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationIsCertFileStaleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationIsCertFileStaleGet$Json(params?: {
    calibrationId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationIsCertFileStaleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationGetCertFilesGet
   */
  static readonly ApiCalibrationGetCertFilesGetPath = '/api/Calibration/get-cert-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationGetCertFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetCertFilesGet$Plain$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationGetCertFilesGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationGetCertFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetCertFilesGet$Plain(params?: {
    calibrationId?: number;
  }): Observable<CalibrationCertFileDtoListDlmBaseResponse> {

    return this.apiCalibrationGetCertFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>) => r.body as CalibrationCertFileDtoListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationGetCertFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetCertFilesGet$Json$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationGetCertFilesGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationGetCertFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetCertFilesGet$Json(params?: {
    calibrationId?: number;
  }): Observable<CalibrationCertFileDtoListDlmBaseResponse> {

    return this.apiCalibrationGetCertFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoListDlmBaseResponse>) => r.body as CalibrationCertFileDtoListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationGetPaginatedCertFilesForSerialNumberGet
   */
  static readonly ApiCalibrationGetPaginatedCertFilesForSerialNumberGetPath = '/api/Calibration/get-paginated-cert-files-for-serial-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Plain$Response(params?: {
    OrderProperty?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
    SerialNumber?: string;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationGetPaginatedCertFilesForSerialNumberGetPath, 'get');
    if (params) {
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
      rb.query('SerialNumber', params.SerialNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Plain(params?: {
    OrderProperty?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
    SerialNumber?: string;
  }): Observable<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse> {

    return this.apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Json$Response(params?: {
    OrderProperty?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
    SerialNumber?: string;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationGetPaginatedCertFilesForSerialNumberGetPath, 'get');
    if (params) {
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
      rb.query('SerialNumber', params.SerialNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Json(params?: {
    OrderProperty?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
    SerialNumber?: string;
  }): Observable<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse> {

    return this.apiCalibrationGetPaginatedCertFilesForSerialNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationUploadCertFileCalibrationIdPost
   */
  static readonly ApiCalibrationUploadCertFileCalibrationIdPostPath = '/api/Calibration/upload-cert-file/{calibrationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationUploadCertFileCalibrationIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCalibrationUploadCertFileCalibrationIdPost$Plain$Response(params: {
    calibrationId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationUploadCertFileCalibrationIdPostPath, 'post');
    if (params) {
      rb.path('calibrationId', params.calibrationId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationUploadCertFileCalibrationIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCalibrationUploadCertFileCalibrationIdPost$Plain(params: {
    calibrationId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationUploadCertFileCalibrationIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationUploadCertFileCalibrationIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCalibrationUploadCertFileCalibrationIdPost$Json$Response(params: {
    calibrationId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationUploadCertFileCalibrationIdPostPath, 'post');
    if (params) {
      rb.path('calibrationId', params.calibrationId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationUploadCertFileCalibrationIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCalibrationUploadCertFileCalibrationIdPost$Json(params: {
    calibrationId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationUploadCertFileCalibrationIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationUpdateCertFilePost
   */
  static readonly ApiCalibrationUpdateCertFilePostPath = '/api/Calibration/update-cert-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationUpdateCertFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationUpdateCertFilePost$Plain$Response(params?: {
    body?: CalibrationCertFileDto
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationUpdateCertFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationUpdateCertFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationUpdateCertFilePost$Plain(params?: {
    body?: CalibrationCertFileDto
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationUpdateCertFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationUpdateCertFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationUpdateCertFilePost$Json$Response(params?: {
    body?: CalibrationCertFileDto
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationUpdateCertFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationUpdateCertFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationUpdateCertFilePost$Json(params?: {
    body?: CalibrationCertFileDto
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationUpdateCertFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationDownloadCertFileFileIdGet
   */
  static readonly ApiCalibrationDownloadCertFileFileIdGetPath = '/api/Calibration/download-cert-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDownloadCertFileFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDownloadCertFileFileIdGet$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDownloadCertFileFileIdGetPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDownloadCertFileFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDownloadCertFileFileIdGet(params: {
    fileId: number;
  }): Observable<string> {

    return this.apiCalibrationDownloadCertFileFileIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiCalibrationDeleteCertFileFileIdDelete
   */
  static readonly ApiCalibrationDeleteCertFileFileIdDeletePath = '/api/Calibration/delete-cert-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteCertFileFileIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDeleteCertFileFileIdDelete$Plain$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteCertFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteCertFileFileIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDeleteCertFileFileIdDelete$Plain(params: {
    fileId: number;
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationDeleteCertFileFileIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteCertFileFileIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDeleteCertFileFileIdDelete$Json$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteCertFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteCertFileFileIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationDeleteCertFileFileIdDelete$Json(params: {
    fileId: number;
  }): Observable<CalibrationCertFileDtoDlmBaseResponse> {

    return this.apiCalibrationDeleteCertFileFileIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationCertFileDtoDlmBaseResponse>) => r.body as CalibrationCertFileDtoDlmBaseResponse)
    );
  }

}
