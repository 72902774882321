import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgxDropzoneComponent } from "ngx-dropzone";
import { WebcamImage } from "ngx-webcam";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "dlm-upload-image-dialog",
  templateUrl: "./dlm-upload-image-dialog.component.html",
  styleUrls: ["./dlm-upload-image-dialog.component.scss"],
})
export class DLMUploadImageDialogComponent implements OnInit {
  files: File[] = [];
  capturedImageUrls: string[] = [];

  public showUploadBtn: boolean = false;
  public blobUrl: SafeUrl;
  public saveOverride: boolean = false;
  public cancelToken: boolean = false;
  stream: any = null;
  status: any = null;
  trigger: Subject<void> = new Subject();
  tracks: MediaStreamTrack[];
  allTracks: MediaStreamTrack[];

  get $trigger(): Observable<void> {
    return this.trigger.asObservable();
  }
  snapshot(event: WebcamImage) {
    this.capturedImageUrls.push(event.imageAsDataUrl);
  }

  constructor(
    public dialogRef: MatDialogRef<DLMUploadImageDialogComponent>,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @ViewChild("dropzone") dropzone: NgxDropzoneComponent;

  ngOnInit(): void {
    this.data?.files.forEach((file) => {
      this.blobUrl = file.url;
      this.files.push(file.file);
      this.saveOverride = true;
    });
    this.cdr.detectChanges();
  }

  onSelect(event: any) {
    var blob = new Blob([...event.addedFiles]);

    this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(blob)
    );

    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files = this.files.filter((x) => x.name != event.name);
  }

  clear(): void {
    this.files = [];
    this.capturedImageUrls = [];
  }

  cancel(): void {
    this.cancelToken = true;
    this.dialogRef.close({
      files: null,
      cancel: true,
    });
    this.clearTracks();
  }

  openVideo() {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 250,
          height: 250,
        },
      })
      .then((res) => {
        this.stream = res;
        this.status = "";
        this.tracks = res.getVideoTracks();
        this.allTracks = res.getTracks();
      })
      .catch((err) => {
        console.log(err);
        this.status = err;
        if (err?.message === "Permission denied") {
          this.status =
            "Permission denied, please try again by approving the access to the camera.";
        } else {
          ("You may not have a camera system, please try again ...");
        }
      });
  }

  captureImage() {
    this.trigger.next();
  }

  removeCaptureImage(url: string) {
    this.capturedImageUrls = this.capturedImageUrls.filter((x) => x != url);
  }

  saveImage() {
    this.capturedImageUrls.forEach((previewImageUrl, i) => {
      const filename =
        "photo" + Math.floor(Math.random() * 100000 + 1) + (i + 1) + ".png";
      const arr = previewImageUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file: File = new File([u8arr], filename, { type: "png" });

      this.files.push(file);
    });

    this.dialogRef.close({
      files: this.files,
      cancel: false,
    });

    this.clearTracks();
  }

  clearTracks() {
    this.allTracks?.forEach((track) => {
      track.stop();
    });
  }
}
