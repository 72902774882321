<div class="base-popup-dialog">
  <a class="flex-col flex items-end" (click)="dialogRef.close(false)">
    <mat-icon>close</mat-icon>
  </a>
  <h1 class="flex justify-center mb-32" matDialogTitle>SLA Settings</h1>
  <form style="margin-top: 10px" [formGroup]="itemForm" (ngSubmit)="submit()">
    <div fxLayout="column" class="flex items-center">
      <div fxFlex="100" class="pr-16">
        <mat-form-field class="slaSettingsInput" floatLabel="always">
          <input
            matInput
            name="priority"
            type="number"
            min="1"
            [formControl]="itemForm.controls['priority']"
            placeholder="Priority"
          />
          <span matSuffix>days</span>
        </mat-form-field>
      </div>

      <div fxFlex="100" class="pr-16">
        <mat-form-field class="slaSettingsInput" floatLabel="always">
          <input
            matInput
            name="normal"
            type="number"
            min="1"
            [formControl]="itemForm.controls['normal']"
            placeholder="Normal"
          />
          <span matSuffix>days</span>
        </mat-form-field>
      </div>

      <div class="flex flex-row mt-32 mb-32 dialog-button-row">
        <button
          mat-stroked-button
          color="accent"
          type="button"
          (click)="dialogRef.close(false)"
        >
          Cancel
        </button>
        <button mat-flat-button class="ml-16" color="accent" [disabled]="itemForm.invalid">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
