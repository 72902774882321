/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { HireReturnFormDto } from '../models/hire-return-form-dto';
import { HireReturnFormDtoBaseResponse } from '../models/hire-return-form-dto-base-response';
import { InvoiceDtoBaseResponse } from '../models/invoice-dto-base-response';
import { PrehireFormDto } from '../models/prehire-form-dto';
import { PrehireFormDtoBaseResponse } from '../models/prehire-form-dto-base-response';
import { QuotationFormDto } from '../models/quotation-form-dto';
import { QuotationFormDtoBaseResponse } from '../models/quotation-form-dto-base-response';
import { RequiredProductDto } from '../models/required-product-dto';
import { RequiredProductDtoBaseResponse } from '../models/required-product-dto-base-response';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFormGetQuotationFormForCardGet
   */
  static readonly ApiFormGetQuotationFormForCardGetPath = '/api/Form/get-quotation-form-for-card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetQuotationFormForCardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormForCardGet$Plain$Response(params?: {
    scrumCardId?: number;
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetQuotationFormForCardGetPath, 'get');
    if (params) {
      rb.query('scrumCardId', params.scrumCardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetQuotationFormForCardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormForCardGet$Plain(params?: {
    scrumCardId?: number;
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormGetQuotationFormForCardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetQuotationFormForCardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormForCardGet$Json$Response(params?: {
    scrumCardId?: number;
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetQuotationFormForCardGetPath, 'get');
    if (params) {
      rb.query('scrumCardId', params.scrumCardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetQuotationFormForCardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormForCardGet$Json(params?: {
    scrumCardId?: number;
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormGetQuotationFormForCardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormGetQuotationFormByIdGet
   */
  static readonly ApiFormGetQuotationFormByIdGetPath = '/api/Form/get-quotation-form-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetQuotationFormByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormByIdGet$Plain$Response(params?: {
    quotationFormId?: number;
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetQuotationFormByIdGetPath, 'get');
    if (params) {
      rb.query('quotationFormId', params.quotationFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetQuotationFormByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormByIdGet$Plain(params?: {
    quotationFormId?: number;
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormGetQuotationFormByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetQuotationFormByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormByIdGet$Json$Response(params?: {
    quotationFormId?: number;
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetQuotationFormByIdGetPath, 'get');
    if (params) {
      rb.query('quotationFormId', params.quotationFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetQuotationFormByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetQuotationFormByIdGet$Json(params?: {
    quotationFormId?: number;
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormGetQuotationFormByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddQuotationFormPost
   */
  static readonly ApiFormAddQuotationFormPostPath = '/api/Form/add-quotation-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddQuotationFormPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddQuotationFormPost$Plain$Response(params?: {
    body?: QuotationFormDto
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddQuotationFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddQuotationFormPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddQuotationFormPost$Plain(params?: {
    body?: QuotationFormDto
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormAddQuotationFormPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddQuotationFormPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddQuotationFormPost$Json$Response(params?: {
    body?: QuotationFormDto
  }): Observable<StrictHttpResponse<QuotationFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddQuotationFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotationFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddQuotationFormPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddQuotationFormPost$Json(params?: {
    body?: QuotationFormDto
  }): Observable<QuotationFormDtoBaseResponse> {

    return this.apiFormAddQuotationFormPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationFormDtoBaseResponse>) => r.body as QuotationFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdateQuotationFormPut
   */
  static readonly ApiFormUpdateQuotationFormPutPath = '/api/Form/update-quotation-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateQuotationFormPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateQuotationFormPut$Plain$Response(params?: {
    body?: QuotationFormDto
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateQuotationFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateQuotationFormPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateQuotationFormPut$Plain(params?: {
    body?: QuotationFormDto
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiFormUpdateQuotationFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateQuotationFormPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateQuotationFormPut$Json$Response(params?: {
    body?: QuotationFormDto
  }): Observable<StrictHttpResponse<InvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateQuotationFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateQuotationFormPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateQuotationFormPut$Json(params?: {
    body?: QuotationFormDto
  }): Observable<InvoiceDtoBaseResponse> {

    return this.apiFormUpdateQuotationFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoBaseResponse>) => r.body as InvoiceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormToggleArchiveQuotationFormPut
   */
  static readonly ApiFormToggleArchiveQuotationFormPutPath = '/api/Form/toggle-archive-quotation-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveQuotationFormPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveQuotationFormPut$Plain$Response(params?: {
    quotationFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveQuotationFormPutPath, 'put');
    if (params) {
      rb.query('quotationFormId', params.quotationFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveQuotationFormPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveQuotationFormPut$Plain(params?: {
    quotationFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveQuotationFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveQuotationFormPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveQuotationFormPut$Json$Response(params?: {
    quotationFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveQuotationFormPutPath, 'put');
    if (params) {
      rb.query('quotationFormId', params.quotationFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveQuotationFormPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveQuotationFormPut$Json(params?: {
    quotationFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveQuotationFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddRequiredProductPost
   */
  static readonly ApiFormAddRequiredProductPostPath = '/api/Form/add-required-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddRequiredProductPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddRequiredProductPost$Plain$Response(params?: {
    body?: RequiredProductDto
  }): Observable<StrictHttpResponse<RequiredProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddRequiredProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddRequiredProductPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddRequiredProductPost$Plain(params?: {
    body?: RequiredProductDto
  }): Observable<RequiredProductDtoBaseResponse> {

    return this.apiFormAddRequiredProductPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RequiredProductDtoBaseResponse>) => r.body as RequiredProductDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddRequiredProductPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddRequiredProductPost$Json$Response(params?: {
    body?: RequiredProductDto
  }): Observable<StrictHttpResponse<RequiredProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddRequiredProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddRequiredProductPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddRequiredProductPost$Json(params?: {
    body?: RequiredProductDto
  }): Observable<RequiredProductDtoBaseResponse> {

    return this.apiFormAddRequiredProductPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RequiredProductDtoBaseResponse>) => r.body as RequiredProductDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdateRequiredProductPut
   */
  static readonly ApiFormUpdateRequiredProductPutPath = '/api/Form/update-required-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateRequiredProductPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateRequiredProductPut$Plain$Response(params?: {
    body?: RequiredProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateRequiredProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateRequiredProductPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateRequiredProductPut$Plain(params?: {
    body?: RequiredProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateRequiredProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateRequiredProductPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateRequiredProductPut$Json$Response(params?: {
    body?: RequiredProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateRequiredProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateRequiredProductPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateRequiredProductPut$Json(params?: {
    body?: RequiredProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateRequiredProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormArchiveRequiredProductPut
   */
  static readonly ApiFormArchiveRequiredProductPutPath = '/api/Form/archive-required-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormArchiveRequiredProductPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveRequiredProductPut$Plain$Response(params?: {
    requiredProductId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormArchiveRequiredProductPutPath, 'put');
    if (params) {
      rb.query('requiredProductId', params.requiredProductId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormArchiveRequiredProductPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveRequiredProductPut$Plain(params?: {
    requiredProductId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormArchiveRequiredProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormArchiveRequiredProductPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveRequiredProductPut$Json$Response(params?: {
    requiredProductId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormArchiveRequiredProductPutPath, 'put');
    if (params) {
      rb.query('requiredProductId', params.requiredProductId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormArchiveRequiredProductPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveRequiredProductPut$Json(params?: {
    requiredProductId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormArchiveRequiredProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormGetPrehireFormByIdGet
   */
  static readonly ApiFormGetPrehireFormByIdGetPath = '/api/Form/get-prehire-form-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPrehireFormByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPrehireFormByIdGet$Plain$Response(params?: {
    prehireFormId?: number;
  }): Observable<StrictHttpResponse<PrehireFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPrehireFormByIdGetPath, 'get');
    if (params) {
      rb.query('prehireFormId', params.prehireFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrehireFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPrehireFormByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPrehireFormByIdGet$Plain(params?: {
    prehireFormId?: number;
  }): Observable<PrehireFormDtoBaseResponse> {

    return this.apiFormGetPrehireFormByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PrehireFormDtoBaseResponse>) => r.body as PrehireFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPrehireFormByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPrehireFormByIdGet$Json$Response(params?: {
    prehireFormId?: number;
  }): Observable<StrictHttpResponse<PrehireFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPrehireFormByIdGetPath, 'get');
    if (params) {
      rb.query('prehireFormId', params.prehireFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrehireFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPrehireFormByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPrehireFormByIdGet$Json(params?: {
    prehireFormId?: number;
  }): Observable<PrehireFormDtoBaseResponse> {

    return this.apiFormGetPrehireFormByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PrehireFormDtoBaseResponse>) => r.body as PrehireFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddPrehireFormPost
   */
  static readonly ApiFormAddPrehireFormPostPath = '/api/Form/add-prehire-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddPrehireFormPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPrehireFormPost$Plain$Response(params?: {
    body?: PrehireFormDto
  }): Observable<StrictHttpResponse<PrehireFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddPrehireFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrehireFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddPrehireFormPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPrehireFormPost$Plain(params?: {
    body?: PrehireFormDto
  }): Observable<PrehireFormDtoBaseResponse> {

    return this.apiFormAddPrehireFormPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PrehireFormDtoBaseResponse>) => r.body as PrehireFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddPrehireFormPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPrehireFormPost$Json$Response(params?: {
    body?: PrehireFormDto
  }): Observable<StrictHttpResponse<PrehireFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddPrehireFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrehireFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddPrehireFormPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPrehireFormPost$Json(params?: {
    body?: PrehireFormDto
  }): Observable<PrehireFormDtoBaseResponse> {

    return this.apiFormAddPrehireFormPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PrehireFormDtoBaseResponse>) => r.body as PrehireFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdatePrehireFormPut
   */
  static readonly ApiFormUpdatePrehireFormPutPath = '/api/Form/update-prehire-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdatePrehireFormPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePrehireFormPut$Plain$Response(params?: {
    body?: PrehireFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdatePrehireFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdatePrehireFormPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePrehireFormPut$Plain(params?: {
    body?: PrehireFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdatePrehireFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdatePrehireFormPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePrehireFormPut$Json$Response(params?: {
    body?: PrehireFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdatePrehireFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdatePrehireFormPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePrehireFormPut$Json(params?: {
    body?: PrehireFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdatePrehireFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormToggleArchivePrehireFormPut
   */
  static readonly ApiFormToggleArchivePrehireFormPutPath = '/api/Form/toggle-archive-prehire-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchivePrehireFormPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchivePrehireFormPut$Plain$Response(params?: {
    prehireFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchivePrehireFormPutPath, 'put');
    if (params) {
      rb.query('prehireFormId', params.prehireFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchivePrehireFormPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchivePrehireFormPut$Plain(params?: {
    prehireFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchivePrehireFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchivePrehireFormPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchivePrehireFormPut$Json$Response(params?: {
    prehireFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchivePrehireFormPutPath, 'put');
    if (params) {
      rb.query('prehireFormId', params.prehireFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchivePrehireFormPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchivePrehireFormPut$Json(params?: {
    prehireFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchivePrehireFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormGetHireReturnFormByIdGet
   */
  static readonly ApiFormGetHireReturnFormByIdGetPath = '/api/Form/get-hire-return-form-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetHireReturnFormByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetHireReturnFormByIdGet$Plain$Response(params?: {
    hireReturnFormId?: number;
  }): Observable<StrictHttpResponse<HireReturnFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetHireReturnFormByIdGetPath, 'get');
    if (params) {
      rb.query('hireReturnFormId', params.hireReturnFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HireReturnFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetHireReturnFormByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetHireReturnFormByIdGet$Plain(params?: {
    hireReturnFormId?: number;
  }): Observable<HireReturnFormDtoBaseResponse> {

    return this.apiFormGetHireReturnFormByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HireReturnFormDtoBaseResponse>) => r.body as HireReturnFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetHireReturnFormByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetHireReturnFormByIdGet$Json$Response(params?: {
    hireReturnFormId?: number;
  }): Observable<StrictHttpResponse<HireReturnFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetHireReturnFormByIdGetPath, 'get');
    if (params) {
      rb.query('hireReturnFormId', params.hireReturnFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HireReturnFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetHireReturnFormByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetHireReturnFormByIdGet$Json(params?: {
    hireReturnFormId?: number;
  }): Observable<HireReturnFormDtoBaseResponse> {

    return this.apiFormGetHireReturnFormByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HireReturnFormDtoBaseResponse>) => r.body as HireReturnFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddHireReturnFormPost
   */
  static readonly ApiFormAddHireReturnFormPostPath = '/api/Form/add-hire-return-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddHireReturnFormPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHireReturnFormPost$Plain$Response(params?: {
    body?: HireReturnFormDto
  }): Observable<StrictHttpResponse<HireReturnFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddHireReturnFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HireReturnFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddHireReturnFormPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHireReturnFormPost$Plain(params?: {
    body?: HireReturnFormDto
  }): Observable<HireReturnFormDtoBaseResponse> {

    return this.apiFormAddHireReturnFormPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HireReturnFormDtoBaseResponse>) => r.body as HireReturnFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddHireReturnFormPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHireReturnFormPost$Json$Response(params?: {
    body?: HireReturnFormDto
  }): Observable<StrictHttpResponse<HireReturnFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddHireReturnFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HireReturnFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddHireReturnFormPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHireReturnFormPost$Json(params?: {
    body?: HireReturnFormDto
  }): Observable<HireReturnFormDtoBaseResponse> {

    return this.apiFormAddHireReturnFormPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HireReturnFormDtoBaseResponse>) => r.body as HireReturnFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdateHireReturnFormPut
   */
  static readonly ApiFormUpdateHireReturnFormPutPath = '/api/Form/update-hire-return-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateHireReturnFormPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHireReturnFormPut$Plain$Response(params?: {
    body?: HireReturnFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateHireReturnFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateHireReturnFormPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHireReturnFormPut$Plain(params?: {
    body?: HireReturnFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateHireReturnFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateHireReturnFormPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHireReturnFormPut$Json$Response(params?: {
    body?: HireReturnFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateHireReturnFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateHireReturnFormPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHireReturnFormPut$Json(params?: {
    body?: HireReturnFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateHireReturnFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormToggleArchiveHireReturnFormPut
   */
  static readonly ApiFormToggleArchiveHireReturnFormPutPath = '/api/Form/toggle-archive-hire-return-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveHireReturnFormPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveHireReturnFormPut$Plain$Response(params?: {
    hireReturnFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveHireReturnFormPutPath, 'put');
    if (params) {
      rb.query('hireReturnFormId', params.hireReturnFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveHireReturnFormPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveHireReturnFormPut$Plain(params?: {
    hireReturnFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveHireReturnFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveHireReturnFormPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveHireReturnFormPut$Json$Response(params?: {
    hireReturnFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveHireReturnFormPutPath, 'put');
    if (params) {
      rb.query('hireReturnFormId', params.hireReturnFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveHireReturnFormPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveHireReturnFormPut$Json(params?: {
    hireReturnFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveHireReturnFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
