import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-dlm-confirm",
  template: ` <a
      class="flex-col flex items-end"
      (click)="dialogRef.close(false)"
    >
      <mat-icon>close</mat-icon>
    </a>
    <div class="flex flex-col items-center">
      <h1 matDialogTitle class="mb-16">{{ data.title }}</h1>
      <img *ngIf="data.isDelete" src="assets/dlm-images/dlm/delete.svg" />
      <div mat-dialog-content class="my-16">{{ data.message }}</div>
    </div>
    <div
      mat-dialog-actions
      class="flex flex-row justify-center w-100"
    >
      <button
        class="mr-8"
        type="button"
        mat-stroked-button
        color="primary"
        style="border:2px solid #0081ff;"
        (click)="dialogRef.close(false)"
      >
        {{ data.cancelText }}
      </button>
      <button
        class="ml-8"
        type="button"
        mat-flat-button
        color="{{ data.isDelete ? 'warn' : 'primary' }}"
        (click)="dialogRef.close(true)"
      >
        {{ data.confirmText }}
      </button>
    </div>`,
  styleUrls: ["./app-confirm.component.scss"],
})
export class DLMAppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<DLMAppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
