/* tslint:disable */
/* eslint-disable */

/**
 * 0 = HIRE_Customers
 * 
 * 1 = HIRE_Dashboard
 * 
 * 2 = HIRE_SalesOrders
 * 
 * 3 = HIRE_Calendar
 * 
 * 4 = HIRE_Assets
 * 
 * 5 = HIRE_Invoicing
 * 
 * 6 = CALIB_AdminDash
 * 
 * 7 = CALIB_WorkshopDash
 * 
 * 8 = CALIB_Servicing
 * 
 * 9 = CALIB_Settings
 * 
 * 10 = CALIB_Customers
 * 
 * 11 = CALIB_Form10a
 * 
 * 12 = SETTINGS_Users
 * 
 * 13 = SETTINGS_Roles
 * 
 * 14 = SETTINGS_CompanyDetails
 * 
 * 15 = SETTINGS_ReleaseNotes
 * 
 * 16 = PROJ_Projects
 * 
 * 17 = CRM_Contacts
 * 
 * 18 = CRM_Leads
 * 
 * 19 = CRM_Pipeline
 * 
 * 20 = CRM_Emails
 * 
 * 21 = CRM_Reporting
 * 
 * 29 = HIRE_AssetBoard
 */
export enum Feature {
  HIRE_Customers = 0,
  HIRE_Dashboard = 1,
  HIRE_SalesOrders = 2,
  HIRE_Calendar = 3,
  HIRE_Assets = 4,
  HIRE_Invoicing = 5,
  CALIB_AdminDash = 6,
  CALIB_WorkshopDash = 7,
  CALIB_Servicing = 8,
  CALIB_Settings = 9,
  CALIB_Customers = 10,
  CALIB_Form10a = 11,
  SETTINGS_Users = 12,
  SETTINGS_Roles = 13,
  SETTINGS_CompanyDetails = 14,
  SETTINGS_ReleaseNotes = 15,
  PROJ_Projects = 16,
  CRM_Contacts = 17,
  CRM_Leads = 18,
  CRM_Pipeline = 19,
  CRM_Emails = 20,
  CRM_Reporting = 21,
  HIRE_AssetBoard = 29
}
