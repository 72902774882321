/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { ProductCategoryDto } from '../models/product-category-dto';
import { ProductCategoryDtoBaseResponse } from '../models/product-category-dto-base-response';
import { ProductCategoryDtoListBaseResponse } from '../models/product-category-dto-list-base-response';
import { ProductCommentDto } from '../models/product-comment-dto';
import { ProductCommentDtoBaseResponse } from '../models/product-comment-dto-base-response';
import { ProductCommentDtoListBaseResponse } from '../models/product-comment-dto-list-base-response';
import { ProductDto } from '../models/product-dto';
import { ProductDtoBaseResponse } from '../models/product-dto-base-response';
import { ProductDtoListBaseResponse } from '../models/product-dto-list-base-response';
import { ProductDtoPagedResponseBaseResponse } from '../models/product-dto-paged-response-base-response';
import { ProductFileDto } from '../models/product-file-dto';
import { ProductFileDtoBaseResponse } from '../models/product-file-dto-base-response';
import { ProductFileDtoListBaseResponse } from '../models/product-file-dto-list-base-response';
import { PropertyFilter } from '../models/property-filter';
import { ScrumBoardListDtoBaseResponse } from '../models/scrum-board-list-dto-base-response';
import { ScrumCardBasicDtoListBaseResponse } from '../models/scrum-card-basic-dto-list-base-response';
import { ScrumCardBasicDtoPagedResponseBaseResponse } from '../models/scrum-card-basic-dto-paged-response-base-response';
import { ScrumCardDto } from '../models/scrum-card-dto';
import { SwimlaneDetailDto } from '../models/swimlane-detail-dto';
import { SwimlaneListDto } from '../models/swimlane-list-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProductPaginatedListGet
   */
  static readonly ApiProductPaginatedListGetPath = '/api/Product/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Plain$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProductDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedListGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Plain(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProductDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResponseBaseResponse>) => r.body as ProductDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Json$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProductDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedListGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Json(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProductDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResponseBaseResponse>) => r.body as ProductDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllGet
   */
  static readonly ApiProductGetAllGetPath = '/api/Product/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Plain(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Json(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductSearchAllGet
   */
  static readonly ApiProductSearchAllGetPath = '/api/Product/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllByCategoryGet
   */
  static readonly ApiProductGetAllByCategoryGetPath = '/api/Product/get-all-by-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllByCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Plain$Response(params?: {
    category?: string;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllByCategoryGetPath, 'get');
    if (params) {
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllByCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Plain(params?: {
    category?: string;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllByCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllByCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Json$Response(params?: {
    category?: string;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllByCategoryGetPath, 'get');
    if (params) {
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllByCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Json(params?: {
    category?: string;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllByCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllAdditionalGet
   */
  static readonly ApiProductGetAllAdditionalGetPath = '/api/Product/get-all-additional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllAdditionalGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllAdditionalGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllAdditionalGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Plain(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllAdditionalGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllAdditionalGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllAdditionalGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllAdditionalGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Json(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllAdditionalGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetProductGet
   */
  static readonly ApiProductGetProductGetPath = '/api/Product/get-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetProductGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Plain$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetProductGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetProductGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Plain(params?: {
    productId?: number;
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductGetProductGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetProductGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Json$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetProductGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetProductGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Json(params?: {
    productId?: number;
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductGetProductGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAddProductPost
   */
  static readonly ApiProductAddProductPostPath = '/api/Product/add-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddProductPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddProductPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Plain(params?: {
    body?: ProductDto
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductAddProductPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddProductPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddProductPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Json(params?: {
    body?: ProductDto
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductAddProductPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateProductPut
   */
  static readonly ApiProductUpdateProductPutPath = '/api/Product/update-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Plain(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Json(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateProductPriorityPut
   */
  static readonly ApiProductUpdateProductPriorityPutPath = '/api/Product/update-product-priority';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPriorityPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPriorityPut$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPriorityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPriorityPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPriorityPut$Plain(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPriorityPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPriorityPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPriorityPut$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPriorityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPriorityPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPriorityPut$Json(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPriorityPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateProductLinkedLoadcellPut
   */
  static readonly ApiProductUpdateProductLinkedLoadcellPutPath = '/api/Product/update-product-linked-loadcell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductLinkedLoadcellPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductLinkedLoadcellPut$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductLinkedLoadcellPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductLinkedLoadcellPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductLinkedLoadcellPut$Plain(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductLinkedLoadcellPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductLinkedLoadcellPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductLinkedLoadcellPut$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductLinkedLoadcellPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductLinkedLoadcellPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductLinkedLoadcellPut$Json(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductLinkedLoadcellPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductToggleArchiveProductPut
   */
  static readonly ApiProductToggleArchiveProductPutPath = '/api/Product/toggle-archive-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductToggleArchiveProductPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Plain$Response(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductToggleArchiveProductPutPath, 'put');
    if (params) {
      rb.query('productId', params.productId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductToggleArchiveProductPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Plain(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductToggleArchiveProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductToggleArchiveProductPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Json$Response(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductToggleArchiveProductPutPath, 'put');
    if (params) {
      rb.query('productId', params.productId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductToggleArchiveProductPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Json(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductToggleArchiveProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAllCategoriesGet
   */
  static readonly ApiProductAllCategoriesGetPath = '/api/Product/all-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllCategoriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductCategoryDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllCategoriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Plain(params?: {
  }): Observable<ProductCategoryDtoListBaseResponse> {

    return this.apiProductAllCategoriesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoListBaseResponse>) => r.body as ProductCategoryDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllCategoriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductCategoryDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllCategoriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Json(params?: {
  }): Observable<ProductCategoryDtoListBaseResponse> {

    return this.apiProductAllCategoriesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoListBaseResponse>) => r.body as ProductCategoryDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetCategoryGet
   */
  static readonly ApiProductGetCategoryGetPath = '/api/Product/get-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Plain$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetCategoryGetPath, 'get');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Plain(params?: {
    productCategoryId?: number;
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductGetCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Json$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetCategoryGetPath, 'get');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Json(params?: {
    productCategoryId?: number;
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductGetCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAddCategoryPost
   */
  static readonly ApiProductAddCategoryPostPath = '/api/Product/add-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Plain$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Plain(params?: {
    body?: ProductCategoryDto
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductAddCategoryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCategoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Json$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCategoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Json(params?: {
    body?: ProductCategoryDto
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductAddCategoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateCategoryPut
   */
  static readonly ApiProductUpdateCategoryPutPath = '/api/Product/update-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCategoryPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Plain$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCategoryPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCategoryPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Plain(params?: {
    body?: ProductCategoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCategoryPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCategoryPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Json$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCategoryPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCategoryPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Json(params?: {
    body?: ProductCategoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCategoryPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductArchiveCategoryPut
   */
  static readonly ApiProductArchiveCategoryPutPath = '/api/Product/archive-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductArchiveCategoryPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Plain$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductArchiveCategoryPutPath, 'put');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductArchiveCategoryPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Plain(params?: {
    productCategoryId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductArchiveCategoryPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductArchiveCategoryPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Json$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductArchiveCategoryPutPath, 'put');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductArchiveCategoryPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Json(params?: {
    productCategoryId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductArchiveCategoryPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAssetBoardGet
   */
  static readonly ApiProductAssetBoardGetPath = '/api/Product/asset-board';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAssetBoardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAssetBoardGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumBoardListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAssetBoardGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAssetBoardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAssetBoardGet$Plain(params?: {
  }): Observable<ScrumBoardListDtoBaseResponse> {

    return this.apiProductAssetBoardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoBaseResponse>) => r.body as ScrumBoardListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAssetBoardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAssetBoardGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumBoardListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAssetBoardGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAssetBoardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAssetBoardGet$Json(params?: {
  }): Observable<ScrumBoardListDtoBaseResponse> {

    return this.apiProductAssetBoardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoBaseResponse>) => r.body as ScrumBoardListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductPaginatedProductsForSwimlaneGet
   */
  static readonly ApiProductPaginatedProductsForSwimlaneGetPath = '/api/Product/paginated-products-for-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedProductsForSwimlaneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedProductsForSwimlaneGet$Plain$Response(params?: {
    SwimlaneId?: number;
    StatusId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedProductsForSwimlaneGetPath, 'get');
    if (params) {
      rb.query('SwimlaneId', params.SwimlaneId, {});
      rb.query('StatusId', params.StatusId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedProductsForSwimlaneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedProductsForSwimlaneGet$Plain(params?: {
    SwimlaneId?: number;
    StatusId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ScrumCardBasicDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedProductsForSwimlaneGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>) => r.body as ScrumCardBasicDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedProductsForSwimlaneGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedProductsForSwimlaneGet$Json$Response(params?: {
    SwimlaneId?: number;
    StatusId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedProductsForSwimlaneGetPath, 'get');
    if (params) {
      rb.query('SwimlaneId', params.SwimlaneId, {});
      rb.query('StatusId', params.StatusId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedProductsForSwimlaneGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedProductsForSwimlaneGet$Json(params?: {
    SwimlaneId?: number;
    StatusId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ScrumCardBasicDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedProductsForSwimlaneGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>) => r.body as ScrumCardBasicDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAllHireAssetsGet
   */
  static readonly ApiProductAllHireAssetsGetPath = '/api/Product/all-hire-assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllHireAssetsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllHireAssetsGet$Plain$Response(params?: {
    searchTerm?: string;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllHireAssetsGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllHireAssetsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllHireAssetsGet$Plain(params?: {
    searchTerm?: string;
  }): Observable<ScrumCardBasicDtoListBaseResponse> {

    return this.apiProductAllHireAssetsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>) => r.body as ScrumCardBasicDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllHireAssetsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllHireAssetsGet$Json$Response(params?: {
    searchTerm?: string;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllHireAssetsGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllHireAssetsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllHireAssetsGet$Json(params?: {
    searchTerm?: string;
  }): Observable<ScrumCardBasicDtoListBaseResponse> {

    return this.apiProductAllHireAssetsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoListBaseResponse>) => r.body as ScrumCardBasicDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateSwimlanePut
   */
  static readonly ApiProductUpdateSwimlanePutPath = '/api/Product/update-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateSwimlanePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanePut$Plain$Response(params?: {
    body?: SwimlaneDetailDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateSwimlanePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateSwimlanePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanePut$Plain(params?: {
    body?: SwimlaneDetailDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateSwimlanePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateSwimlanePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanePut$Json$Response(params?: {
    body?: SwimlaneDetailDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateSwimlanePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateSwimlanePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanePut$Json(params?: {
    body?: SwimlaneDetailDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateSwimlanePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductDeleteSwimlaneDelete
   */
  static readonly ApiProductDeleteSwimlaneDeletePath = '/api/Product/delete-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteSwimlaneDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteSwimlaneDelete$Plain$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteSwimlaneDeletePath, 'delete');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteSwimlaneDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteSwimlaneDelete$Plain(params?: {
    swimlaneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductDeleteSwimlaneDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteSwimlaneDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteSwimlaneDelete$Json$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteSwimlaneDeletePath, 'delete');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteSwimlaneDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteSwimlaneDelete$Json(params?: {
    swimlaneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductDeleteSwimlaneDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateSwimlanesPut
   */
  static readonly ApiProductUpdateSwimlanesPutPath = '/api/Product/update-swimlanes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateSwimlanesPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanesPut$Plain$Response(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateSwimlanesPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanesPut$Plain(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateSwimlanesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateSwimlanesPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanesPut$Json$Response(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateSwimlanesPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateSwimlanesPut$Json(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateSwimlanesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateProductPositionsPut
   */
  static readonly ApiProductUpdateProductPositionsPutPath = '/api/Product/update-product-positions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPositionsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPositionsPut$Plain$Response(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPositionsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPositionsPut$Plain(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPositionsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPositionsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPositionsPut$Json$Response(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPositionsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPositionsPut$Json(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPositionsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductCommentsGet
   */
  static readonly ApiProductCommentsGetPath = '/api/Product/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductCommentsGet$Plain$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductCommentsGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductCommentsGet$Plain(params?: {
    productId?: number;
  }): Observable<ProductCommentDtoListBaseResponse> {

    return this.apiProductCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCommentDtoListBaseResponse>) => r.body as ProductCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductCommentsGet$Json$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductCommentsGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductCommentsGet$Json(params?: {
    productId?: number;
  }): Observable<ProductCommentDtoListBaseResponse> {

    return this.apiProductCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCommentDtoListBaseResponse>) => r.body as ProductCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAddCommentPost
   */
  static readonly ApiProductAddCommentPostPath = '/api/Product/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCommentPost$Plain$Response(params?: {
    body?: ProductCommentDto
  }): Observable<StrictHttpResponse<ProductCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCommentPost$Plain(params?: {
    body?: ProductCommentDto
  }): Observable<ProductCommentDtoBaseResponse> {

    return this.apiProductAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCommentDtoBaseResponse>) => r.body as ProductCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCommentPost$Json$Response(params?: {
    body?: ProductCommentDto
  }): Observable<StrictHttpResponse<ProductCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCommentPost$Json(params?: {
    body?: ProductCommentDto
  }): Observable<ProductCommentDtoBaseResponse> {

    return this.apiProductAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCommentDtoBaseResponse>) => r.body as ProductCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateCommentPut
   */
  static readonly ApiProductUpdateCommentPutPath = '/api/Product/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCommentPut$Plain$Response(params?: {
    body?: ProductCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCommentPut$Plain(params?: {
    body?: ProductCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCommentPut$Json$Response(params?: {
    body?: ProductCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCommentPut$Json(params?: {
    body?: ProductCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductDeleteCommentDelete
   */
  static readonly ApiProductDeleteCommentDeletePath = '/api/Product/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetFilesGet
   */
  static readonly ApiProductGetFilesGetPath = '/api/Product/get-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetFilesGet$Plain$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetFilesGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetFilesGet$Plain(params?: {
    productId?: number;
  }): Observable<ProductFileDtoListBaseResponse> {

    return this.apiProductGetFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoListBaseResponse>) => r.body as ProductFileDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetFilesGet$Json$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetFilesGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetFilesGet$Json(params?: {
    productId?: number;
  }): Observable<ProductFileDtoListBaseResponse> {

    return this.apiProductGetFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoListBaseResponse>) => r.body as ProductFileDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUploadFileProductIdPost
   */
  static readonly ApiProductUploadFileProductIdPostPath = '/api/Product/upload-file/{productId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUploadFileProductIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProductUploadFileProductIdPost$Plain$Response(params: {
    productId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUploadFileProductIdPostPath, 'post');
    if (params) {
      rb.path('productId', params.productId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUploadFileProductIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProductUploadFileProductIdPost$Plain(params: {
    productId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductUploadFileProductIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUploadFileProductIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProductUploadFileProductIdPost$Json$Response(params: {
    productId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUploadFileProductIdPostPath, 'post');
    if (params) {
      rb.path('productId', params.productId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUploadFileProductIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProductUploadFileProductIdPost$Json(params: {
    productId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductUploadFileProductIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateFilePost
   */
  static readonly ApiProductUpdateFilePostPath = '/api/Product/update-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateFilePost$Plain$Response(params?: {
    body?: ProductFileDto
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateFilePost$Plain(params?: {
    body?: ProductFileDto
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductUpdateFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateFilePost$Json$Response(params?: {
    body?: ProductFileDto
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateFilePost$Json(params?: {
    body?: ProductFileDto
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductUpdateFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductDownloadFileFileIdGet
   */
  static readonly ApiProductDownloadFileFileIdGetPath = '/api/Product/download-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDownloadFileFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDownloadFileFileIdGet$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDownloadFileFileIdGetPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDownloadFileFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDownloadFileFileIdGet(params: {
    fileId: number;
  }): Observable<string> {

    return this.apiProductDownloadFileFileIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiProductDeleteFileFileIdDelete
   */
  static readonly ApiProductDeleteFileFileIdDeletePath = '/api/Product/delete-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteFileFileIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteFileFileIdDelete$Plain$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteFileFileIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteFileFileIdDelete$Plain(params: {
    fileId: number;
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductDeleteFileFileIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDeleteFileFileIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteFileFileIdDelete$Json$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<ProductFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductDeleteFileFileIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDeleteFileFileIdDelete$Json(params: {
    fileId: number;
  }): Observable<ProductFileDtoBaseResponse> {

    return this.apiProductDeleteFileFileIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductFileDtoBaseResponse>) => r.body as ProductFileDtoBaseResponse)
    );
  }

}
