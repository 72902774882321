<div class="upload-image-file-dialog-container">
  <a class="flex-col flex items-end" (click)="cancel()">
    <mat-icon class="closeIcon">close</mat-icon>
  </a>
  <h5 class="text-center mb-32">Upload / capture image</h5>
  <div class="mb-32 flex flex-row justify-around">
    <ngx-dropzone
      #dropzone
      (change)="onSelect($event)"
      class="flex flex-col justify-center"
      [style]="files.length > 1 ? 'justify-content: start !important' : ''"
    >
      <ngx-dropzone-label>
        <div>
          <mat-icon class="material-icons-outlined"
            >add_photo_alternate</mat-icon
          >
        </div>
        <div class="mt-8">
          <small>UPLOAD A PHOTO</small>
        </div>
      </ngx-dropzone-label>
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        *ngFor="let f of files"
        [file]="f"
        [removable]="true"
        (removed)="onRemove(f)"
        class="img-prev"
      >
        <ngx-dropzone-label>
          <!-- <a [href]="this.blobUrl" [download]="f.name"> -->
          <a>{{ f.name }}</a>
        </ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>

    <div
      class="flex flex-col justify-center cameraDiv"
      [style]="
        capturedImageUrls.length > 1 ? 'justify-content: start !important' : ''
      "
    >
      <a (click)="openVideo()" *ngIf="capturedImageUrls.length == 0">
        <div class="cameraIcon">
          <mat-icon class="material-icons-outlined">camera_alt</mat-icon>
        </div>
        <div class="mt-8">
          <small>TAKE A PHOTO</small>
        </div>
      </a>

      <div *ngFor="let previewImageUrl of capturedImageUrls" class="previewDiv">
        <ngx-dropzone-remove-badge
          class="previewRemove"
          (click)="removeCaptureImage(previewImageUrl)"
        ></ngx-dropzone-remove-badge>
        <div
          class="flex flex-col justify-center items-center"
        >
          <img [src]="previewImageUrl" />
        </div>
      </div>
    </div>
  </div>

  <p class="mt-32 error" *ngIf="status">Status: {{ status }}</p>

  <div class="mt-16 flex flex-row justify-center">
    <div
      *ngIf="stream"
      class="flex flex-col justify-center items-center"
    >
      <webcam
        [height]="250"
        [width]="250"
        [trigger]="$trigger"
        (imageCapture)="snapshot($event)"
        [imageQuality]="1"
      ></webcam>
      <button
        mat-flat-button
        class="cameraButton rounded-button items-center justify-center"
        color="primary"
        (click)="captureImage()"
      >
        Capture the image
      </button>
    </div>
  </div>

  <div class="flex flex-row mt-8 justify-center">
    <button
      *ngIf="capturedImageUrls.length > 0 || files.length > 0"
      class="mx-16"
      (click)="clear()"
    >
      Clear
    </button>
    <button
      *ngIf="capturedImageUrls.length > 0 || files.length > 0 || saveOverride"
      class="mx-16 upload-btn"
      (click)="saveImage()"
    >
      Save
    </button>
    <button class="mx-16" (click)="cancel()">Cancel</button>
  </div>
</div>
