<div class="flex flex-col flex-auto max-h-210 -m-6 overflow-y-auto details-container">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Task Details</div>
        <button
            mat-icon-button
            (click)="matDialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Card form -->
    <form
        *ngIf="cardForm"
        class="flex flex-col flex-0 items-start w-full space-t-6"
        [formGroup]="cardForm"
        (keydown.enter)="$event.preventDefault()">

        <div class="details-content grid grid-cols-6 items-start w-full p-6 sm:p-8 space-y-4 overflow-y-auto">

            <!-- Left Column -->
            <div class="col-span-6 flex flex-col flex-0 md:col-span-4">

                <!-- Title -->
                <mat-form-field
                    class="w-full"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="text-lg">Title</mat-label>
                    <textarea
                        matInput
                        [formControlName]="'title'"
                        [rows]="1"
                        cdkTextareaAutosize
                        [cdkAutosizeMinRows]="1"
                        maxlength="200"
                        [readonly]="isBasic">
                    </textarea>
                </mat-form-field>

                <div>
                    <!-- Description -->
                    <quill-editor
                        class="mt-2 w-full"
                        [formControlName]="'description'"
                        [bounds]="'self'"
                        [modules]="quillModules"
                        (onEditorCreated)="onEditorCreated($event)"
                        [readOnly]="isBasic">
                    </quill-editor>
                </div>

                <!-- Location -->
                <div class="w-full mt-1" *ngIf="!isAssetBoard">
                    <div class="font-medium">Location</div>
                    <div class="flex flex-row w-full elipsis relative mt-1.5">
                        <mat-form-field
                            appearance="outline"
                            class="flex flex-1 location-field"
                            [subscriptSizing]="'dynamic'">
                            <mat-icon
                                matPrefix
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_outline:map-pin'">
                            </mat-icon>
                            <input
                                matInput
                                [formControlName]="'location'">

                            <button
                                matSuffix
                                *ngIf="cardForm.controls['location']?.value"
                                mat-icon-button
                                class="w-9 h-9 min-h-9 ml-1"
                                (click)="openLocation()"
                                matTooltip="Open Location"
                                >
                                <!-- style="margin-right: -4px;" -->
                                <mat-icon class="icon-size-4" [svgIcon]="'mat_outline:open_in_new'"></mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Sales Order Client -->
                <div class="w-full mt-1" *ngIf="isAssetBoard">
                    <div class="font-medium">Client</div>
                    <div class="flex flex-row w-full elipsis relative mt-1.5">
                        <mat-form-field
                            appearance="outline"
                            class="flex flex-1 location-field"
                            [subscriptSizing]="'dynamic'">
                            <mat-icon
                                matPrefix
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_outline:user'">
                            </mat-icon>
                            <input
                                matInput
                                [value]="getClientText()"
                                readonly>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Attachments -->
                <div class="w-full mt-3 col-span-6 flex flex-row flex-0 overflow-x-auto overflow-y-hidden" style="height:102px;">                
                    <div> <!--class="-ml-2"-->
                        <!-- Upload file -->
                        <button mat-icon-button
                        (click)="openFileUpload()">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:paper-clip'"></mat-icon>
                        </button>
                    </div>

                    <!-- Files -->
                    <ng-container *ngIf="files?.length > 0">
                        <div class="file-container p-2 mx-1" *ngFor="let file of files">
                            <!-- Icons -->
                            <div class="relative cursor-pointer text-center" (click)="download(file)">
                                <div class="inline-block relative text-center">
                                    <mat-icon
                                        class="icon-size-14 text-hint opacity-50"
                                        [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                    <div
                                        class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                        [class.bg-red-600]="file.type == '.pdf'"
                                        [class.bg-blue-600]="file.type == '.doc'"
                                        [class.bg-green-600]="file.type == '.xls'"
                                        [class.bg-gray-600]="file.type == '.txt'"
                                        [class.bg-amber-600]="file.type == '.jpg'"
                                        [class.bg-yellow-500]="file.type == '.png'">
                                        {{file.type.toUpperCase()}}
                                    </div>
                                    <button *ngIf="!isBasic" class="absolute" (click)="deleteFile(file, $event)">
                                        <mat-icon class="icon-size-2 text-red-600">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="file-name cursor-pointer text-s text-center mt-1" (click)="download(file)">{{file.name}}</div>
                        </div>
                    </ng-container>
                </div>


                <!-- Hire Return Form -->
                <div class="w-full my-3 col-span-6 flex flex-col gap-4" *ngIf="isAssetBoard">

                    <!-- toggle and button for hire return form -->
                    <mat-slide-toggle
                        *ngIf="isAdmin"
                        [checked]="requireHireReturnForm"
                        (change)="onToggleRequireHireReturnForm($event)"
                        [color]="'primary'"
                        [disabled]="isBasic">
                        <span class="ml-2">Repair Required</span>
                    </mat-slide-toggle>

                    <div>
                        <button
                            *ngIf="requireHireReturnForm"
                            class="mt-1"
                            mat-flat-button
                            [color]="'primary'"
                            [matTooltip]="'Add Damage Questionnaire'"
                            (click)="addAndOpenHireReturnForm()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            Add Damage Questionnaire
                        </button>
                    </div>
                </div>

            </div>

            <!-- Right Column -->
            <div class="col-span-6 flex flex-col flex-0 md:ml-6 md:col-span-2">

                <!-- Selects -->
                <div class="grid grid-cols-2 gap-4 w-full">
                    <!-- Start Date -->
                    <div class="col-span-1">
                        <div class="font-medium">{{ isAssetBoard ? 'Hire ' : ''}}Start Date</div>
                        <div
                            class="relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"                    
                            (click)="!isBasic && startDatePicker.open()">
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            <span class="ml-2 text-md font-medium">
                                <ng-container *ngIf="cardForm?.get('startDate').value">{{cardForm?.get('startDate').value | date:'dd/MM/yy'}}</ng-container>
                                <ng-container *ngIf="!cardForm?.get('startDate').value">Not set</ng-container>
                            </span>
                            <mat-form-field
                                class="fuse-mat-dense invisible absolute inset-0 -mt-2.5 opacity-0 pointer-events-none"
                                [subscriptSizing]="'dynamic'">
                                <input
                                    matInput
                                    [formControlName]="'startDate'"
                                    [matDatepicker]="startDatePicker"
                                    [readonly]="isBasic || isAssetBoard">
                                <mat-datepicker *ngIf="!isBasic && !isAssetBoard" #startDatePicker>
                                    <mat-datepicker-actions>
                                        <button
                                            mat-button
                                            (click)="cardForm?.get('startDate').setValue(null)"
                                            matDatepickerCancel>
                                            Clear
                                        </button>
                                        <button
                                            mat-flat-button
                                            [color]="'primary'"
                                            matDatepickerApply>
                                            Select
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Due date -->
                    <div class="col-span-1">
                        <div class="font-medium">{{ isAssetBoard ? 'Hire Return' : 'Due'}} Date</div>
                        <div
                            class="relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer"
                            [ngClass]="{'text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700': !cardForm?.get('dueDate').value,
                                        'text-green-800 bg-green-200 dark:text-green-100 dark:bg-green-500': cardForm?.get('dueDate').value && !isOverdue(cardForm?.get('dueDate').value),
                                        'text-red-800 bg-red-200 dark:text-red-100 dark:bg-red-500': cardForm?.get('dueDate').value && isOverdue(cardForm?.get('dueDate').value)}"
                            (click)="!isBasic && dueDatePicker.open()">
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            <span class="ml-2 text-md font-medium">
                                <ng-container *ngIf="cardForm?.get('dueDate').value">{{cardForm?.get('dueDate').value | date:'dd/MM/yy'}}</ng-container><!--d MMM yyyy-->
                                <ng-container *ngIf="!cardForm?.get('dueDate').value">Not set</ng-container>
                            </span>
                            <mat-form-field
                                class="fuse-mat-dense invisible absolute inset-0 -mt-2.5 opacity-0 pointer-events-none"
                                [subscriptSizing]="'dynamic'">
                                <input
                                    matInput
                                    [formControlName]="'dueDate'"
                                    [matDatepicker]="dueDatePicker"
                                    [readonly]="isBasic || isAssetBoard">
                                <mat-datepicker *ngIf="!isBasic && !isAssetBoard" #dueDatePicker>
                                    <mat-datepicker-actions>
                                        <button
                                            mat-button
                                            (click)="cardForm?.get('dueDate').setValue(null)"
                                            matDatepickerCancel>
                                            Clear
                                        </button>
                                        <button
                                            mat-flat-button
                                            [color]="'primary'"
                                            matDatepickerApply>
                                            Select
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Collection date (assets) -->
                    <div class="col-span-1" *ngIf="isAssetBoard">
                        <div class="font-medium">Collection Date</div>
                        <div
                            class="time-estimate relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 w-full"
                            >
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                                <p class="ml-1">
                                    {{ getCollectionDate() | date:'dd/MM/yy' }}
                                </p>
                        </div>
                    </div>

                    <!-- Hours Estimate -->
                    <div class="col-span-1" *ngIf="!isAssetBoard">
                        <div class="font-medium">Estimated Time</div>
                        <div
                            class="time-estimate relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 w-full"
                            (click)="!isBasic && changeEstimatePopup()"
                            >
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_outline:clock'"></mat-icon>
                                <p class="ml-1">
                                    <ng-container *ngIf="card?.hoursEstimate">{{ getTotalHours() }}</ng-container>
                                    <ng-container *ngIf="card?.hoursEstimate === 0">Not set</ng-container>
                                </p>
                        </div>
                    </div>

                    <!-- Assign User -->
                    <div class="col-span-1">
                        <div class="font-medium">Assigned User</div>
                        <div class="custom-mat-form-field w-full elipsis text-center mx-auto relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                            <mat-form-field
                                class="w-full custom-mat-select"
                                [subscriptSizing]="'dynamic'">
                                <mat-icon
                                    class="user-icon icon-size-5 text-current"
                                    [svgIcon]="'heroicons_outline:user'">
                                </mat-icon>
                                <mat-select
                                    multiple
                                    class="user-select"
                                    [formControlName]="'userIds'"
                                    [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                                    disableOptionCentering
                                    #userSelect="matSelect"
                                    (selectionChange)="updateUser($event)">
                                    
                                    <mat-select-trigger>
                                        <div class="ml-1 font-medium">
                                            <span class="ml-2 text-md font-medium">
                                                <p>{{ getUserTriggerList() }}</p>
                                            </span>
                                        </div>
                                    </mat-select-trigger>
                                    <!-- <mat-option
                                        class="h-auto py-4 leading-none"
                                        [value]="null"
                                        [disabled]="isBasic">
                                        <div class="font-medium">Unassigned</div>
                                    </mat-option> -->
                                    <ng-container *ngFor="let user of users">
                                        <mat-option
                                            class="h-auto py-4 leading-none"
                                            [value]="user?.id"
                                            [disabled]="isBasic">
                                            <div class="font-medium">{{user.firstName + " " + user.lastName}}</div>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Priority -->
                    <div class="col-span-1">
                        <div class="font-medium">Priority</div>
                        <div class="custom-mat-form-field w-full elipsis text-center mx-auto relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                            <mat-form-field
                                class="w-full custom-mat-select"
                                [subscriptSizing]="'dynamic'">
                                <mat-icon
                                    class="user-icon icon-size-5 text-current"
                                    [svgIcon]="'mat_outline:flag'">
                                </mat-icon>
                                <mat-select
                                    class="user-select"
                                    [value]="card?.priority"
                                    [formControlName]="'priority'"
                                    [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                                    disableOptionCentering
                                    #prioritySelect="matSelect">
                                    
                                    <mat-select-trigger>
                                        <div class="ml-1 font-medium">
                                            <span class="ml-2 text-md font-medium">
                                                <p>{{getPriorityName(cardForm.controls['priority']?.value)}}</p>
                                            </span>    
                                        </div>
                                    </mat-select-trigger>
                                    <ng-container *ngFor="let priority of priorities">
                                        <mat-option
                                            class="h-auto py-4 leading-none"
                                            [value]="priority.value"
                                            [disabled]="isBasic">
                                            <div class="font-medium">{{priority.name}}</div>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <!-- Location (Asset) -->
                    <div class="col-span-1" *ngIf="isAssetBoard">
                        <div class="font-medium">Location</div>
                        <div
                            class="time-estimate relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 w-full"
                            >
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_outline:map-pin'"></mat-icon>
                                <p class="ml-1">
                                    {{ card?.location }}
                                </p>
                        </div>
                    </div>
                    
                    <!-- Sales Order Number -->
                    <div class="col-span-1" *ngIf="isAssetBoard">
                        <div class="font-medium">Sales Order Number</div>
                        <div
                            class="time-estimate relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 w-full"
                            >
                                <p class="ml-1 truncate">
                                    {{ getSalesOrderNumber() }}
                                </p>
                        </div>
                    </div>
                    
                    <!-- Calibration Due Date -->
                    <div class="col-span-1" *ngIf="isAssetBoard">
                        <div class="font-medium">Calibration Date</div>
                        <div
                            class="time-estimate relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 w-full"
                            >
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                                <p class="ml-1">
                                    {{ getCalibrationDueDate() | date:'dd/MM/yy' }}
                                </p>
                        </div>
                    </div>

                    <div class="col-span-2">
                        <div class="font-medium">Status</div>
                        <div class="custom-mat-form-field w-full elipsis text-center mx-auto relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                            <mat-form-field
                                class="w-full custom-mat-select"
                                [subscriptSizing]="'dynamic'">
                                <mat-select
                                    class="user-select"
                                    [formControlName]="'swimlaneId'"
                                    [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                                    disableOptionCentering
                                    #roleSelect="matSelect"
                                    (selectionChange)="updateLane($event)">
                                    <ng-container *ngFor="let lane of swimlanes">
                                        <mat-option
                                            class="h-auto py-4 leading-none"
                                            [value]="lane.id"
                                            [disabled]="isBasic">
                                            <div class="font-medium">{{lane.title}}</div>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error *ngIf="cardForm?.controls['swimlaneId'].hasError('required')">
                                    Please select a swimlane
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-span-2 mt-2" *ngIf="!isAssetBoard">
                        <!-- Billable Toggle -->
                        <mat-slide-toggle
                            [checked]="isBillable"
                            (change)="onToggle($event)"
                            [color]="'primary'"
                            [disabled]="isBasic">
                            <span class="ml-2">{{isBillable ? '' : 'Not'}} Billable</span>
                        </mat-slide-toggle>

                        <!-- Form Toggle - admins on select environments only -->
                        <mat-slide-toggle
                            *ngIf="isAdmin && quotationFormFeatureEnabled"
                            class="ml-2"
                            [checked]="requireForm"
                            (change)="onToggleRequireForm($event)"
                            [color]="'primary'"
                            [disabled]="isBasic">
                            <span class="ml-2">{{requireForm ? 'Require' : 'No'}} Form</span>
                        </mat-slide-toggle>
                    </div>


                    <div class="col-span-2 flex flex-row items-center gap-2" *ngIf="!isAssetBoard">
                        <div class="font-medium">Progress</div>
                        <mat-slider min="0" max="100" step="5" discrete [displayWith]="formatSlider">
                            <input #slider matSliderThumb [value]="percentageComplete" (valueChange)="percentageComplete = $event;">
                        </mat-slider>
                        <span>{{slider.value}}%</span>
                    </div>

                </div>

                <!-- Labels -->
                <div class="w-full mt-4">
                    <div class="font-medium">Labels</div>
                    <div class="mt-1 rounded-md border border-gray-300 shadow-sm overflow-hidden" style="height: 175px;">
                        <!-- Header -->
                        <div class="flex items-center my-2 mx-3">
                            <div class="flex items-center flex-auto min-w-0">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                <input
                                    class="min-w-0 ml-2 py-1 border-0"
                                    type="text"
                                    placeholder="Enter label name"
                                    (input)="filterLabels($event)"
                                    (keydown)="filterLabelsInputKeyDown($event)"
                                    [maxLength]="50"
                                    [readonly]="isBasic">
                            </div>
                        </div>
                        <!-- Available labels -->
                        <div class="max-h-175 leading-none overflow-y-auto border-t pt-2">
                            <!-- Labels -->
                            <ng-container *ngFor="let label of filteredLabels; trackBy: trackByFn">
                                <div class="flex flex-row w-full">
                                    <mat-checkbox
                                        class="flex items-center h-10 min-h-10 pl-1"
                                        [color]="'primary'"
                                        [checked]="hasLabel(label)"
                                        (change)="toggleProductTag(label, $event)"
                                        [disabled]="isBasic">
                                        <p *ngIf="editingLabel?.id != label.id" 
                                            class="rounded-full px-2 py-1" 
                                            [style]="'border: ' + getLabelBorderStyle(label?.colour) + '; color: ' + getLabelTextColour(label?.colour) + '; background-color: ' + label?.colour"
                                        >
                                            {{label.title}}
                                        </p>
                                    </mat-checkbox>

                                    <div class="flex flex-row items-center h-10 min-h-10 pl-1 pr-4 mr-auto" *ngIf="editingLabel?.id == label.id">
                                        <input
                                            style="width: 85px;"
                                            class="min-w-0 py-1 pr-1 border-0"
                                            type="text"
                                            placeholder="{{ editingLabel?.title }}"
                                            (input)="editLabelName($event, label)"
                                            [maxLength]="50">

                                        <button
                                            [style]="'border: ' + getLabelBorderStyle(editingLabel?.colour) + '; color: ' + getLabelTextColour(editingLabel?.colour) + '; background-color: ' + editingLabel?.colour"
                                            class="rounded-full px-2 py-2"
                                            [matTooltip]="'Select colour'"
                                            [matMenuTriggerFor]="colourPickerMenu"
                                            #colourMenu="matMenuTrigger">
                                            <span class="text-sm">Colour</span>
                                            <mat-menu #colourPickerMenu="matMenu" class="colour-picker-menu">
                                                <md-color-picker 
                                                    #picker
                                                    value="editingLabel?.colour" 
                                                    palette="material"
                                                    default-tint="a700"
                                                    use-spectrum-picker="false"
                                                    (change)="editLabelColour($event, label)"
                                                ></md-color-picker>
                                            </mat-menu>
                                        </button>
                                    </div>
                                    
                                    <!-- Edit -->
                                    <button
                                        *ngIf="!isBasic && label?.id && editingLabel?.id != label?.id"
                                        class="flex items-center h-10 min-h-10 pl-1 ml-auto mr-3"
                                        mat-icon-button
                                        [matTooltip]="'Edit label'"
                                        (click)="editLabel(label)">
                                        <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                                    </button>

                                    <!-- Finish Editing -->
                                    <ng-container *ngIf="label?.id && editingLabel?.id == label?.id">
                                        <!-- Cancel -->
                                        <button
                                            *ngIf="label?.id"
                                            class="flex items-center h-10 min-h-10 pl-1 ml-auto mr-1"
                                            mat-icon-button
                                            [matTooltip]="'Cancel'"
                                            (click)="cancelLabelEdit()">
                                            <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                                        </button>
                                        <!-- Save -->
                                        <button
                                            *ngIf="label?.id"
                                            class="flex items-center h-10 min-h-10 pl-1 mr-3"
                                            mat-icon-button
                                            [matTooltip]="'Save'"
                                            [disabled]="!cardForm.valid"
                                            (click)="saveLabelEdit($event)">
                                            <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                        </button>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Comments -->
            <div class="col-span-6">
                <ng-container *ngIf="cardId">
                    <!-- <div class="mt-2 mb-4 border-t"></div> -->
                    <div class="w-full mt-4 md:-mt-2">
                        <app-comments
                            [comments]="genericComments"
                            [parentId]="card?.id"
                            [titleClass]="'text-lg'"
                            ($commentAdded)="addComment($event)"
                            ($commentChanged)="updateComment($event)"
                            ($commentDeleted)="removeComment($event)">
                        </app-comments>
                    </div>
                </ng-container>
            </div>

            <!-- Task [User Assignment] History -->
            <div class="col-span-6" *ngIf="card?.history">
                <div class="text-lg mt-4">History</div>
                <ng-container *ngFor="let history of card?.history">
                    <div class="italic whitespace-pre-line mt-4">
                        {{ history.description }}
                    </div>
                    <div class="text-sm mt-2" style="color: black;">
                        {{ history.timeStamp | date:'d MMM yyyy, h:mm a' }}
                    </div>
                </ng-container>
            </div>

        </div>

        <!-- Actions -->
        <div class="flex items-center justify-end w-full border-t p-6 sm:p-8">
            <!-- Delete -->
            <button
                *ngIf="!isBasic"
                (click)="archiveCard()"
                mat-stroked-button
                color="warn"
                style="margin-right: auto;"
                [disabled]="!card?.id"
                type="button">
                Delete
            </button>

            <!-- Cancel -->
            <button
                class="ml-auto"
                mat-button
                [matTooltip]="'Cancel'"
                (click)="close()">
                Cancel
            </button>
            <!-- Save -->
            <button
                class="ml-2"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!cardForm?.valid"
                [matTooltip]="'Save'"
                (click)="save()">
                Save
            </button>
        </div>
    </form>
</div>
