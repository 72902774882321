import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";

// SERVICES
import { ThemeService } from "./services/theme.service";
// import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./guards/auth.guard";
import { UserRoleGuard } from "./guards/user-role.guard";
import { DLMAppConfirmService } from "./services/app-confirm/app-confirm.service";

import { DLMSharedComponentsModule } from "./components/shared-components.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { EmployeeService } from "app/api/services";

@NgModule({
  imports: [
    CommonModule,
    DLMSharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  providers: [
    DecimalPipe,
    ThemeService,
    // NavigationService,
    RoutePartsService,
    AuthGuard,
    UserRoleGuard,
    DLMAppConfirmService,
    // AppLoaderService,
    EmployeeService,
  ],
  exports: [DLMSharedComponentsModule, SharedPipesModule, SharedDirectivesModule],
})
export class DLMSharedModule {}
