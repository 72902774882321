<div class="flex flex-col justify-center items-center">
  <h2 class="title-text my-8">QR Scanner</h2>
  <div class="qr-code-container">
    <zxing-scanner
      class="m-16"
      #scanner
      [formats]="['QR_CODE']"
      (scanSuccess)="scanSuccessHandler($event)"
    >
    </zxing-scanner>
    <img class="scanner-overlay" src="assets/dlm-images/qrscanner.png" />
  </div>
</div>
