<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent z-0">
        <!-- dummy background to appear behind drawer button and title -->
        <div style="height: 40px;"></div>
        <!-- Title -->
        <!-- <div class="flex-1 min-w-0">
            <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                {{board?.title}}
            </h2>
        </div> -->
        <!-- Actions -->
        <!-- <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <a
                mat-stroked-button
                [routerLink]="['..']">
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:view-columns'"></mat-icon>
                <span class="ml-2">Boards</span>
            </a>
            <button
                class="ml-3"
                mat-stroked-button>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
                <span class="ml-2">Settings</span>
            </button>
        </div> -->
    </div>

    <!-- Main -->
    <div
        class="flex-auto p-6 sm:p-8 sm:pt-4 sm:pb-5 overflow-y-hidden">

        <!-- Swimlanes -->
        <div
            class="flex h-full overflow-y-hidden overflow-x-auto"
            cdkScrollable
            cdkDropList
            [cdkDropListData]="board?.swimlanes"
            [cdkDropListOrientation]="'horizontal'"
            (cdkDropListDropped)="listDropped($event)">

            <!-- Group all cdkDropLists after this point together so that the cards can be transferred between swimlanes -->
            <div
                class="flex items-start"
                cdkDropListGroup>

                <!-- Swimlane -->
                <ng-container *ngFor="let list of board?.swimlanes; trackBy: trackByFn">
                    <div
                        class="h-full overflow-hidden flex-0 w-72 p-2 rounded-2xl relative"
                        style="background-color: #f8f8f8;"
                        cdkDrag
                        [cdkDragDisabled]="isAssetBoard"
                        [cdkDragLockAxis]="'x'">
                        <div
                            class="flex items-center justify-between"
                            cdkDragHandle>
                            <div class="flex items-center w-full py-2 px-3 rounded-md cursor-text border border-transparent focus-within:bg-white focus-within:shadow-sm focus-within:border-primary dark:focus-within:bg-gray-900">
                                <input
                                    class="w-full font-bold leading-5 bg-transparent font-16"
                                    [spellcheck]="'false'"
                                    [value]="list.title"
                                    (focusout)="updateListTitle($event, list)"
                                    (keydown.enter)="listTitleInput.blur()"
                                    #listTitleInput
                                    [disabled]="isAssetBoard">
                            </div>

                            <button
                                #hoverButton
                                style="z-index: 1050"
                                class="flex items-center justify-center min-w-6 ml-4 text-sm font-semibold leading-6 rounded-full bg-gray-300 text-secondary dark:bg-gray-700"
                                [matMenuTriggerFor]="summaryMenu" #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()" (mouseleave)="menuTrigger.closeMenu()"
                            >
                                {{getTotalCountForSwimlane(list.id)}}
                                <!-- Hover Menu -->
                                <ng-container *ngIf="getTotalHoursForSwimlane(list.id) != -1">
                                    <mat-menu #summaryMenu="matMenu" [class.hidden]="" class="summary-menu text-sm leading-6 p-4" overlapTrigger="false" hasBackdrop="false">
                                        <span>
                                            <p class="font-bold">Total Estimated Time:</p>
                                            <p>{{ getTotalHoursForSwimlane(list.id) }}</p>
                                        </span>
                                    </mat-menu>
                                </ng-container>
                            </button>

                            <div class="ml-1">
                                <button
                                    class="w-8 h-8 min-h-8"
                                    mat-icon-button
                                    [matMenuTriggerFor]="listMenu">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
                                </button>
                                <mat-menu #listMenu="matMenu">
                                    <button
                                        *ngIf="!isAssetBoard"
                                        mat-menu-item
                                        (click)="renameList(listTitleInput)">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                        Rename list
                                    </button>

                                    <button
                                        mat-menu-item
                                        [matMenuTriggerFor]="colourPickerMenu"
                                        #colourMenu="matMenuTrigger">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'mat_solid:palette'"></mat-icon>
                                            Select Colour
                                    </button>
                                    <mat-menu #colourPickerMenu="matMenu" class="colour-picker-menu">
                                        <md-color-picker 
                                            #picker
                                            value="getListColour(list)" 
                                            palette="material"
                                            default-tint="a700"
                                            use-spectrum-picker="false"
                                            (change)="changeSwimlaneColour($event, list)"
                                        ></md-color-picker>
                                    </mat-menu>

                                    <button
                                        *ngIf="!isAssetBoard"
                                        mat-menu-item
                                        (click)="deleteList(list.id)">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                        Delete list
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <div class="swimlane-underline" [style.border-color]="list.colour"></div>

                        <!-- Scrollable Card Container -->
                        <div
                            [id]="'swimlane' + list.id"
                            class="h-full overflow-y-auto overflow-x-hidden"
                            [style]="'height: calc(100% - ' + isAssetBoard ? 36 : 88 + 'px) !important; scrollbar-gutter: stable;'"
                            cdkScrollable
                            (scroll)="onScroll($event, list)">

                            <!-- Cards -->
                            <div class="mt-2 rounded-xl bg-gray-400 bg-opacity-12 dark:bg-transparent dark:border" style="min-height: calc(100% - 0.5rem);">
                                <div
                                    [id]="list.id"
                                    class="p-3"
                                    style="min-height: calc(100vh - 265px);"
                                    cdkDropList
                                    [cdkDropListData]="list.cards"
                                    (cdkDropListDropped)="cardDropped($event)">

                                    <!-- Card -->
                                    <ng-container *ngFor="let card of list.cards; trackBy: trackByFn">
                                        <div cdkDrag cdkDragHandle [cdkDragDisabled]="isAssetBoard">
                                            <a
                                                class="flex flex-col items-start mb-3 p-5 space-y-3 shadow rounded-lg overflow-hidden bg-card"
                                                [class.flag-calib-due-card]="isAssetBoard && isWithin30Days(card.calibrationDueDate)"
                                                (click)="openCardDetails(card)"
                                                >
                                                <!-- Cover image -->
                                                <ng-container *ngIf="card.coverImage">
                                                    <div class="-mx-5 -mt-5 mb-2">
                                                        <img
                                                            class="w-full object-cover"
                                                            [src]="card.coverImage">
                                                    </div>
                                                </ng-container>
                                                <div class="w-full flex flex-row justify-between">
                                                    <!-- Title -->
                                                    <div class="text-lg font-medium leading-5" style="max-width: 184px;">{{card.title}}</div>

                                                    <!-- Complete Task -->
                                                    <mat-icon
                                                        *ngIf="!!projectId"
                                                        (click)="completeTask($event, card)"
                                                        class="hover-check"
                                                        [style.color]="card?.isCompleted ? '#12df12' : ''"
                                                        [svgIcon]="card?.isCompleted ? 'heroicons_solid:check-circle' : 'heroicons_outline:check-circle'">
                                                    </mat-icon>
                                                </div>
                                                
                                                <!-- Assigned User -->
                                                <!-- <div
                                                    *ngIf="card.userId"                                            
                                                    class="items-center justify-center w-8 h-8 rounded-full"
                                                >
                                                    <ng-container *ngIf="hasAvatar(card.userId)">
                                                        <img
                                                            class="object-cover rounded-full w-full h-full"
                                                            [src]="getAvatar(card.userId)"
                                                            alt="Contact avatar"/>
                                                    </ng-container>
                                                    <ng-container *ngIf="!hasAvatar(card.userId)">
                                                        <div class="flex items-center justify-center min-w-6 p-1 text-sm font-semibold leading-6 rounded-full bg-gray-300 text-secondary dark:bg-gray-700">
                                                            {{getUserInitials(getUser(card.userId))}}
                                                        </div>
                                                    </ng-container>
                                                </div> -->

                                                <!-- Multiple Assigned Users -->
                                                <div class="flex items-center mt-6 -space-x-1.5" *ngIf="card?.userIds?.length">
                                                    <ng-container *ngFor="let userId of card?.userIds?.split(',').slice(0, 5); trackBy: trackByFn">
                                                        <div class="relative flex flex-0 items-center justify-center w-8 h-8" [matTooltip]="getUserName(userId) || ''">
                                                            <ng-container *ngIf="hasAvatar(userId)">
                                                                <img
                                                                    class="w-full h-full rounded-full object-cover"
                                                                    [src]="getAvatar(userId)"
                                                                    alt="User Avatar"/>
                                                            </ng-container>
                                                            <ng-container *ngIf="!hasAvatar(userId)">
                                                                    <div
                                                                        class="w-full h-full rounded-full object-cover flex items-center justify-center w-full h-full rounded-full text-lg uppercase text-gray-600"
                                                                        [style]="'background: ' + getUserColour(userId)"
                                                                    >
                                                                        <!-- {{member?.name.charAt(0)}} -->
                                                                        {{getUserInitials(getUser(userId))}}
                                                                    </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="card?.userIds?.split(',').length > 5">
                                                        <div class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring ring-offset-1 ring-bg-card ring-offset-transparent bg-gray-200 text-gray-500">
                                                            <div class="text-md font-semibold">
                                                                +{{ card?.userIds?.split(',').slice(5).length }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>


                                                <div class="flex flex-wrap -mx-1 -mb-2 w-full">
                                                    <!-- Due date -->
                                                    <ng-container *ngIf="card.dueDate">
                                                        <div
                                                            class="flex items-center rounded text-sm font-medium leading-5 text-secondary"
                                                            [ngClass]="{'text-red-600': isOverdue(card.dueDate)}"
                                                            matTooltip="Due {{card.dueDate| date:'MMMM d, yyyy'}}">
                                                            <mat-icon
                                                                class="icon-size-4 text-current"
                                                                [svgIcon]="'heroicons_outline:calendar'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{card.dueDate | date: 'longDate'}}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                
                                                <!-- Icons -->
                                                <div class="flex flex-wrap -mx-1 -mb-2 w-full gap-2 justify-between">
                                                    <ng-container *ngIf="card.commentCount">
                                                        <div
                                                            class="flex items-center rounded text-sm font-medium leading-5 text-secondary"
                                                            [matTooltip]="card.commentCount + ' comment' + (card.commentCount > 1 ? 's' : '')">
                                                            <mat-icon
                                                                class="icon-size-4 text-current"
                                                                [svgIcon]="'heroicons_outline:chat-bubble-oval-left'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{card.commentCount}}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    
                                                    <ng-container *ngIf="card.percentageComplete">
                                                        <div
                                                            class="flex items-center rounded text-sm font-medium leading-5 text-secondary"
                                                            [matTooltip]="card.percentageComplete + '% complete'">
                                                            <mat-icon
                                                                class="icon-size-4 text-current"
                                                                [svgIcon]="'mat_outline:timelapse'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{card.percentageComplete}}%
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="card.hoursEstimate">
                                                        <div
                                                            class="flex items-center rounded text-sm font-medium leading-5 text-secondary"
                                                            [matTooltip]="getHoursText(card.hoursEstimate) + ' estimated'">
                                                            <mat-icon
                                                                class="icon-size-4 text-current"
                                                                [svgIcon]="'mat_outline:hourglass_empty'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{ getHoursTextMinimal(card.hoursEstimate) }}
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="card.hoursSpent">
                                                        <div
                                                            class="flex items-center rounded text-sm font-medium leading-5 text-secondary"
                                                            [matTooltip]="getHoursText(card.hoursSpent) + ' spent'">
                                                            <mat-icon
                                                                class="icon-size-4 text-current"
                                                                [svgIcon]="'mat_outline:timer'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{ getHoursTextMinimal(card.hoursSpent) }}
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="isAssetBoard && isWithin30Days(card.calibrationDueDate)">
                                                        <div
                                                            class="flex items-center text-sm font-medium leading-5 text-secondary"
                                                            matTooltip="Calibration due {{card.calibrationDueDate | date: 'd MMM yyyy'}}">
                                                            <mat-icon
                                                                class="icon-size-4 text-warn"
                                                                [svgIcon]="'heroicons_solid:wrench-screwdriver'"></mat-icon>
                                                            <div class="ml-1">
                                                                {{ daysToCalibText(card.calibrationDueDate) }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                
                                                <!-- Labels -->
                                                <ng-container *ngIf="card.labels.length">
                                                    <div>
                                                        <div class="flex flex-wrap -mx-1 -mb-2">
                                                            <ng-container *ngFor="let label of card.labels; trackBy: trackByFn; let first = first">
                                                                <div 
                                                                    class="rounded-full px-2 py-0.5 mr-1 mb-2"
                                                                    [class.ml-1]="!first"
                                                                    [style]="'border: ' + getLabelBorderStyle(label?.colour) + '; color: ' + getLabelTextColour(label?.colour) + '; background-color: ' + label?.colour">
                                                                    {{label.title}}
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- New card -->
                        <scrumboard-board-add-card
                            *ngIf="!isAssetBoard"
                            class="pb-2 mr-2 add-card-cont"
                            (saved)="addCard(list, $event)"
                            [buttonTitle]="list.cards.length ? 'Add another card' : 'Add a card'">
                        </scrumboard-board-add-card>
                    </div>
                    
                </ng-container>

                <!-- New list -->
                <scrumboard-board-add-list
                    *ngIf="!isAssetBoard"
                    (saved)="addList($event)"
                    [buttonTitle]="board?.swimlanes?.length ? 'Add another swimlane' : 'Add a swimlane'">
                </scrumboard-board-add-list>

            </div>

        </div>

    </div>

</div>

<!-- Invisible router-outlet for ScrumboardCard component -->
<div class="absolute invisible w-0 h-0 opacity-0 pointer-events-none">
    <router-outlet></router-outlet>
</div>
