import { Observable } from "rxjs";
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { Injectable } from "@angular/core";

import { DLMAppComfirmComponent } from "./app-confirm.component";

interface confirmData {
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  isDelete?: boolean;
}

@Injectable()
export class DLMAppConfirmService {
  constructor(private dialog: MatDialog) {}

  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title = data.title || "Confirm";
    data.message = data.message || "Are you sure?";
    data.confirmText = data.confirmText || "Delete";
    data.cancelText = data.cancelText || "Cancel";
    data.isDelete = data.isDelete == null ? true : data.isDelete;
    let dialogRef: MatDialogRef<DLMAppComfirmComponent>;
    dialogRef = this.dialog.open(DLMAppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        confirmText: data.confirmText,
        cancelText: data.cancelText,
        isDelete: data.isDelete,
      },
    });
    return dialogRef.afterClosed();
  }
}
